.btn-courses a {
  width: 100%;
  background: #FF9C00 !important;
  border-radius: 8px !important;
  text-align: center !important;
  color: #292929 !important;
  padding: 22px !important;
  font-size: 20px !important;
  line-height: 26px !important;
  transition: all .5s ease;
  position: relative;
  z-index: 1;
  font-weight: 400;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  display: block;
}

.btn-courses a:hover {
  background-color: #fca82a !important;
  transform: translateY(-7.5px) !important;
  transition-delay: .0s !important;
  text-decoration: none !important;
}

@media screen and (max-width: 992px) {
  .btn-courses a {
    margin-top: 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
}