.companyCard {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  padding: 20px;
  background: #F7F6FF;
  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.2);
  border-radius: 8px;
  transition: all .5s ease;
}

.companyCard:hover {
  transform: translateY(-15px) !important;
}

.companyCard img {
  width: 140px;
}

@media screen and (max-width: 992px) {
  .companyCard {
    width: 40%;
  }

  .companyCard img {
    width: 90%
  }
}