section.about-me {
  padding: 140px 0;
}

section.about-me .bg-shadow {
  position: absolute;
  left: calc((33% / 2) - 190px);
  top: -140px;
  z-index: 0;
}

section.about-me .photo {
  display: block;
  width: 400px;
  height: 400px;
  margin: 0 auto;
  object-fit: cover;
  position: relative;
  z-index: 1;
  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.2);
  border-radius: 8px;
}

section.about-me .title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #292929;
  position: relative;
  z-index: 1;
}

section.about-me .desc {
  position: relative;
  z-index: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.005em;
  color: #292929;
}

#app.dark section.about-me .title,
#app.dark section.about-me .desc {
  color: #F7F6FF !important;
}


@media screen and (max-width: 992px) {
  section.about-me .bg-shadow {
    left: calc(50% - 190px);
    top: -30px;
  }

  section.about-me .photo {
    width: 75%;
    height: auto;
  }

  section.about-me {
    padding: 30px 0;
  }

  section.about-me .title {
    text-align: center;
    margin-top: 24px;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 16px;
  }

  section.about-me .desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.005em;
  }
}