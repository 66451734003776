section.donations .donations_info {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
}

#app.dark section.donations .donations_info {
  background-color: #292929 !important;
}

section.donations .donation-img {
  max-width: 50%;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

section.donations .title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}


#app.dark section.donations .title {
  color: #fff;
}

section.donations .address {
  text-align: center;
  font-size: 18px;
}

#app.dark section.donations .address {
  color: #fff;
}

@media screen and (max-width: 992px) {
  section.donations .address {
    text-align: center;
    font-size: 15px;
  }
}