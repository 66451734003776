.sidebar {
  position: fixed;
  left: 0;
  top: 73px;
  background-color: #fff;
  z-index: 4;
  padding: 10px 20px;
  max-width: 250px;
  overflow-y: auto;
  max-height: calc(100vh - 73px);
  transition: all .35s ease;
}

/* width */
.sidebar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: #ff9c00;
  border-radius: 10px;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #ff9c00;
}

.sidebar_category .sidebar_category_name {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.sidebar_category .sidebar_link {
  margin: 10px 0;

}

.sidebar_category .sidebar_link a {
  color: rgba(123, 123, 123, .8) !important;
  text-decoration: none;
}

.sidebar_category .sidebar_link a.active {
  color: #000 !important;
  background-color: #ff9c00;
  padding: 2.5px 5px;
  border-radius: 2.5px;
  font-weight: bold;
}

#app.dark .sidebar {
  background: #1e2124 !important;
}


#app.dark .sidebar_category .sidebar_link a {
  color: rgba(200, 200, 200, .8) !important;
}


#app.dark .sidebar_category .sidebar_link a.active {
  color: #000 !important;
}

#app.dark .sidebar_category .sidebar_category_name {
  color: #fff !important;
}


#app.dark .sidebar::-webkit-scrollbar-track {
  background-color: #000 !important;
}

.sidebar.off {
  left: -70%;
}

.sidebar.on {
  left: 0;
}