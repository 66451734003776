footer {
  background: #F7F6FF;
  box-shadow: 0px 32px 64px 10px rgba(16, 24, 40, 0.2);
  height: 165px;
}

#app.dark footer {
  background: #292929 !important;
  box-shadow: 32px 0px 64px rgba(0, 0, 0, 0.81);
}

footer .container {
  height: 165px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer .container .logo {
  width: 46px;
}

footer .container p.contact {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.0025em;
  margin: 0;
  color: #292929;
}

footer .container a.mail {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.0025em;
  color: #292929;
}

footer .social-link {
  font-size: 25px;
  margin-left: 12px;
  color: #292929;
}

#app.dark footer .social-link,
#app.dark footer .container a.mail,
#app.dark footer .container p.contact {
  color: #fff !important;
}