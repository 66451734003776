.btn-donations {
  background-color: #ff9c00;
  font-size: 1.875rem;
  display: block;
  margin: 0 auto;
  color: #fff;
  border-radius: 10px;
  transition: all .5s ease;
  margin-top: 30px;
}

.btn-donations:hover {
  color: #fff;
  transform: translateY(-7.5px) !important;
  transition-delay: .0s !important;
}