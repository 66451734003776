.btn-theme {
  width: 40px;
  height: 40px;
  background: #292929;
  border: 2px solid #292929;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease;
}

.btn-theme img {
  width: 24px;
}

.btn-theme.dark {
  background-color: #F7F6FF !important;
  border: 2px solid #F7F6FF;
}

@media screen and (max-width: 992px) {
  .btn-theme {
    width: 30px;
    height: 30px;
  }

  .btn-theme img {
    width: 14px;
  }

}