section.course-video .video-wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding-left: 200px;
  position: relative;
}

section.course-video .video-wrapper iframe {
  border-radius: 20px;
}

section.course-video .video-wrapper .loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

@media screen and (max-width: 1450px) {
  section.course-video .video-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    padding-left: 200px;
  }
}

@media screen and (max-width: 1100px) {
  section.course-video .video-wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding-left: 200px;
  }
}

@media screen and (max-width: 992px) {
  section.course-video .video-wrapper {
    max-width: unset;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}