section.companies {
  padding-top: 100px;
  padding-bottom: 20px;
  position: relative;
  overflow-y: hidden;
}

section.companies h2.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #292929;
  text-align: center;
}

section.companies .courses {
  margin-top: 80px;
  width: 100%;
  background: linear-gradient(107.46deg, rgba(0, 0, 0, 0.048) 1.21%, rgba(0, 0, 0, 0.2) 100%);
  backdrop-filter: blur(4px);
  padding: 60px;
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}

#app.dark section.companies .courses {
  background: linear-gradient(107.46deg, rgba(255, 255, 255, 0.048) 1.21%, rgba(236, 236, 236, 0.2) 100%);
}

section.companies .courses .container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-direction: row;
  align-items: center;
}

section.companies .label {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #292929;
  text-align: left;
  line-height: 48px;
  width: 135%;
}

#app.dark section.companies .label {
  color: #F7F6FF !important;
}

section.companies .arch {
  position: absolute;
  bottom: -60px;
  z-index: 0;
  right: 60px;
}

section.companies .btn-courses {
  width: 100%;
}

@media screen and (max-width: 992px) {
  section.companies {
    padding-top: 20px;
  }

  section.companies h2.title {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    max-width: 90%;
    display: block;
    margin: 0 auto;
  }

  section.companies .courses .container {
    flex-direction: column;
  }

  section.companies .courses {
    padding: 36px 16px;
  }

  section.companies .label {
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    width: auto;
  }

  section.companies .btn-courses a {
    margin-top: 0;
  }
}