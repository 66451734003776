section.course-info {
    background-color: #f4f2f2;
}

section.course-info .content-wrapper {
    max-width: 1300px;
    margin: 0 auto;
    margin: 0 auto;
    padding: 20px 0;
    padding-left: 200px;
}

section.course-info .title-wrapper {
    padding: 5px 10px;
    background-color: #F7F6FF;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 10px;
    box-shadow: 0 0 #0000, 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

section.course-info .title-wrapper h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: bold;
    margin: 0;
}

section.course-info .title-wrapper .course-detail {
    text-transform: uppercase;
    margin: 0;
}

#app.dark section.course-info {
    background-color: #464646;
}

#app.dark section.course-info .title-wrapper {
    background-color: #292929;
}

#app.dark section.course-info .title-wrapper h1,
#app.dark section.course-info .title-wrapper .course-detail {
    color: #fff;
}

#app.dark section.course-info p.desc,
#app.dark section.course-info p.donations {
    color: #ddd;
}

@media screen and (max-width: 1450px) {
    section.course-info .content-wrapper {
        max-width: 1000px;
        margin: 0 auto;
        padding-left: 200px;
    }
}

@media screen and (max-width: 1100px) {
    section.course-info .content-wrapper {
        max-width: 800px;
        margin: 0 auto;
        padding-left: 200px;
    }
}

@media screen and (max-width: 992px) {
    section.course-info .content-wrapper {
        max-width: unset;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
    }
}