section.video .video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

section.video .video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 20px;
}

section.video .circle {
  position: absolute;
  top: -200px;
  left: -100px;
  z-index: 0;
  transform: rotate(90deg);
  z-index: -1;
}