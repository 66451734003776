@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700');

* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: background-color .5s ease;
}

body,
html {
  /* height: 100%; */
  position: relative;
}

#app {
  background-color: #F7F6FF !important;
}

#app.dark {
  background-color: #292929 !important;
}

.width-wrapper {
  max-width: 2000px;
  margin: 0 auto;
  position: relative;
  height: 100%;
}

.btn-next,
.btn-prev {
  background: #ff9c00;
  border-radius: 8px;
  color: #292929;
  font-size: 14px;
  padding: 5px 20px;

}


@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px !important;
  }
}