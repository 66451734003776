section.moment {
  padding-top: 70px;
  padding-bottom: 90px;
  overflow: hidden;
  position: relative;
}

section.moment .circle {
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 0;
}

#app.dark section.moment .circle {
  filter: invert();
}

section.moment h1.title {
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #292929;
  margin-bottom: 60px;
}


section.moment h2.subtitle {
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  letter-spacing: -0.005em;
  /* typo_light */
  color: #292929;
}

section.moment .social-link {
  color: #000;
  font-size: 26px;
  display: inline-block;
  margin: 0 7.5px;
  transition: all .5s ease;
}

#app.dark section.moment .social-link {
  color: #fff;
}

section.moment .social-link:hover {
  color: #FF9C00 !important;
}

section.moment .img-coins {
  position: relative;
  background: linear-gradient(107.46deg, rgba(255, 255, 255, 0.048) 1.21%, rgba(236, 236, 236, 0.2) 100%);
  backdrop-filter: blur(4px);
  border-radius: 18px;
  z-index: 1;
}

section.moment p.desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.005em;
  margin-bottom: 30px;
}

#app.dark section.moment h1.title,
#app.dark section.moment h2.subtitle,
#app.dark section.moment p.desc {
  color: #F7F6FF !important;
}

section.moment .btn-courses a {
  width: 100%;
  background: #FF9C00;
  border-radius: 8px;
  text-align: center;
  color: #292929;
  padding: 22px;
  font-size: 20px;
  line-height: 26px;
  transition: all .5s ease;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 992px) {
  section.moment {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  section.moment h1.title {
    text-align: left;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: -0.005em;
    margin-bottom: 15px;
  }

  section.moment h2.subtitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.005em;
    margin-bottom: 16px;
    max-width: 70%;
  }

  section.moment .circle {
    top: unset;
    bottom: -50px;
  }

  section.moment p.desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}