header .navbar {
    background-color: #F7F6FF;
}

header {
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
}

header .navbar .nav-item a {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #292929 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    background-color: transparent;
    transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
    box-shadow: inset 0 0 0 0 #FF9C00;
}

header .navbar .nav-item a.social-link {
    padding: 0 !important;
}

#app.dark header .navbar .nav-item a {
    color: #F7F6FF !important;
}

header .navbar .nav-item.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    gap: 12px;
}

.navbar-light .navbar-toggler {
    border: none;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(../img/navbar-bars.svg) !important;
}

header .navbar .nav-item .social-link {
    color: #292929 !important;
    font-size: 22px;
}

#app.dark header .navbar {
    background: #292929 !important;
}

header .navbar .nav-item.theme {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 2px solid #292929;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

#app.dark header .navbar .nav-item.theme {
    border-left: 2px solid #F7F6FF !important;
}

header .navbar .nav-item:not(.social-links) a:first-child:hover {
    box-shadow: inset 150px 0 0 0 #FF9C00;
    color: white !important;
}

header .navbar .nav-item.social-links a:hover,
#app.dark header .navbar .nav-item.social-links a:hover {
    color: #FF9C00 !important;
}

@media screen and (max-width: 992px) {
    header .navbar-collapse ul {
        height: 100vh;
    }
    header .navbar .nav-link {
        text-align: center;
    }
    header .navbar .nav-item.social-links {
        /* display: none; */
        margin-left: 0;
        gap: 5px;
    }
    header .navbar .nav-item:first-child {
        /* margin-top: 40px; */
    }
    header .navbar .nav-item {
        /* margin-bottom: 7.5%; */
    }
    header .navbar .nav-item .social-link {
        font-size: 21px;
    }
    header .navbar {
        padding: 15px 1rem;
    }
    header .navbar .container {
        /* justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 20px; */
    }
    .navbar .navbar-brand {
        height: 40px;
    }
    header .navbar .nav-item a {
        font-size: 14px;
        padding: 0.5rem 7.5px !important;
        margin-right: 10px;
    }
    header .navbar .nav-item.theme {
        padding-left: 10px;
        margin-left: 10px;
    }
}