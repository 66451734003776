.CompanyContainer {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  gap: 40px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .CompanyContainer {
    flex-wrap: wrap;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 30px;
  }
}