section.stats {
  background-color: #FF9C00 !important;
  min-height: 400px;
  position: relative;
}

section.stats .content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 400px;
  z-index: 1;
  position: relative;
}

section.stats .dots {
  position: absolute;
  right: 0;
  top: 10px;
  opacity: 0.8;
}

section.stats .item {
  width: 33%;
  text-align: center;
}

section.stats .item .name {
  font-weight: 700;
  font-size: 70px;
  line-height: 60px;
  color: #292929;
  margin-bottom: 14px;
  z-index: 1;
}

section.stats .item .desc {
  font-weight: 400;
  color: #292929;
  font-size: 24px;
  line-height: 26px;
  margin: 0;
}

section.stats .bg-shadow {
  position: absolute;
  left: calc((33% / 2) - 190px);
  bottom: 0;
  z-index: 0;
}

@media screen and (max-width: 992px) {
  section.stats {
    overflow-y: hidden;
  }

  section.stats .dots {
    display: none;
  }

  section.stats .content-wrapper {
    flex-direction: column;
    gap: 65px;
  }

  section.stats .item .name {
    font-size: 48px;
    line-height: 45px;
  }

  section.stats .item .desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
  }

  section.stats .content-wrapper {
    padding: 40px 0;
  }

  section.stats .item {
    width: 100%;
  }

  section.stats .bg-shadow {
    left: calc(50% - 190px);
    bottom: -150px;
  }
}